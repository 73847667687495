<template>
  <div>
    <h1 class="text-h1 mb-24">Saved in Tags</h1>
    <Tag
      v-for="tag in getCurrentTagged"
      :key="tag.oid"
      :tag="tag"
    />
    <SidebarAlert v-if="!getCurrentTagged.length">
      <template v-slot:title>Assign profiles to a tag</template>
      <template v-slot:text>
        Tags are one of the main tools of our service. With their help, you can find connections between profiles and generate a report.
      </template>
    </SidebarAlert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Tag from '@/components/tags/Tag';
import SidebarAlert from '@/components/sidebar/Alert';

export default {
  components: {
    Tag,
    SidebarAlert
  },
  async created () {
    this.setCurrentTagged();
  },
  computed: {
    ...mapGetters(['getCurrentTagged'])
  },
  methods: {
    ...mapActions(['setCurrentTagged'])
  }
};
</script>
