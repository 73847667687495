<template>
  <div
    v-if="tag"
    class="mb-46"
  >
    <div class="d-flex align-center mb-12">
      <div class="item-tag is-tag mr-12">tag</div>
      <Tooltip
        :text="tag.name"
        :limit="26"
        className="text-h3"
      />
      <div class="text-h3 color-text-lighter ml-20">{{ profilesLength }} profiles, {{ connectionsLength }} connections</div>
    </div>
    <div class="text-base color-text-lighter mb-24">Group of your main targets.</div>

    <div
      v-for="node in nodes"
      :key="node.title"
    >
      <template
        v-if="node.title === 'Profiles'"
      >
        <Profile
          v-for="profile in node.nodes"
          :key="profile.oid"
          :profile="profile"
          height="h-200px"
          class="mb-12"
        />
      </template>
      <template
        v-else
      >
        <ConnectionsCard
          v-for="connection in node.nodes"
          :key="connection.oid"
          :connection="connection"
          class="mb-12"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/UI/Tooltip';
import Profile from '@/components/full-view/Profile';
import ConnectionsCard from '@/components/connections/Card';

export default {
  components: {
    Tooltip,
    Profile,
    ConnectionsCard
  },
  props: {
    tag: { default: null }
  },
  computed: {
    nodes () {
      const nodes = [];
      const profiles = this.tag.nodes.filter(node => !node?.fields?.relation);
      const relations = this.tag.nodes.filter(node => node?.fields?.relation);
      if (profiles.length) nodes.push({ title: 'Profiles', nodes: profiles });
      if (relations.length) nodes.push({ title: 'Connections', nodes: relations });
      return nodes;
    },
    profilesLength () {
      return this.tag.nodes.filter(node => !node?.fields?.relation).length;
    },
    connectionsLength () {
      return this.tag.nodes.filter(node => node?.fields?.relation).length;
    }
  }
};
</script>
