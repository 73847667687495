<template>
  <Layout>
    <template v-slot:sidebar>
      <SidebarCase />
    </template>
    <template v-slot:content>
      <Tags />
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/UI/Layout';
import SidebarCase from '@/viewsNew/SidebarCase';
import Tags from '@/components/tags/Tags';

export default {
  components: {
    Layout,
    SidebarCase,
    Tags
  }
};
</script>
